function application() {

  this.ready = function() {
    this.drawertoogle();
    this.resetdrawer();
    this.ajaxSetup();
    this.setLabel();
    this.tooltipsEnable();
    this.enableDelete();
    this.tinymceEnabler();
    this.enableSelect2();
    this.enableAdds();
    this.setCurrencyFormat();
    this.hideLoader();
  }

  this.hideLoader = function() {
    $('.wait').fadeOut('slow');
  }

  this.showLoader = function() {
    $('.wait').fadeIn('slow');
  }

  this.serializeList = function(id, selector, directChild) {
    if(typeof directChild === typeof undefined || directChild === '') directChild = true;
    if(typeof id === typeof undefined || id === '') throw new Error("Invalid list id");
    var obj = [];
    selector = selector || 'li';
    var el = directChild ? $('>' + selector, id) : $(selector, id);
    for (var i = 0; i < el.length; i++) {
      var sublist = $(el[i]).find('ul');
      var child = [];
      if(sublist.length > 0) {
        var id = typeof $(sublist).attr('id') !== typeof undefined ? $(sublist).attr('id') : $(sublist).prop('id');
        child = this.serializeList('#'+id, selector);
        obj.push({id: $(el[i]).data('id'), children: child});
      } else {
        obj.push({id: $(el[i]).data('id')});
      }
    }
    return obj;
  }

  this.enableSelect2 = function() {
    if($('.select2').length > 0) {
      var $select2 = $('.select2');
      for (var i = $select2.length - 1; i >= 0; i--) {
        $($select2[i]).select2({
          placeholder: $($select2[i]).attr('title'),
          allowClear: true,
          closeOnSelect: false,
          language: {
            errorLoading: function () {
              return 'I risultati non possono essere caricati.';
            },
            inputTooLong: function (args) {
              var overChars = args.input.length - args.maximum;

              var message = 'Per favore cancella ' + overChars + ' caratter';

              if (overChars !== 1) {
                message += 'i';
              } else {
                message += 'e';
              }

              return message;
            },
            inputTooShort: function (args) {
              var remainingChars = args.minimum - args.input.length;

              var message = 'Per favore inserisci ' +remainingChars+ ' o più caratteri';

              return message;
            },
            loadingMore: function () {
              return 'Caricando più risultati…';
            },
            maximumSelected: function (args) {
              var message = 'Puoi selezionare solo ' + args.maximum + ' element';

              if (args.maximum !== 1) {
                message += 'i';
              } else {
                message += 'o';
              }

              return message;
            },
            noResults: function () {
              return 'Nessun risultato trovato';
            },
            searching: function () {
              return 'Sto cercando…';
            }
          }
        });
      }
    }
  }

  this.index = 0;

  this.enableAdds = function() {
    $('[data-op="adds"]').unbind('click');
    $('[data-op="adds"]').on('click', function (e) {
      app.index++;
      var endpoint = $(this).data('route');
      var target = $(this).data('target');
      var parameters = {};
      if(typeof $(this).data('parameters') !== typeof undefined) parameters = $(this).data('parameters');
      parameters.id = "i"+app.index;
      console.log(parameters);
      e.preventDefault();
      $.ajax({
         url: endpoint,
         headers: {
          'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
         },
         type: 'POST',
         dataType: 'html',
         data: parameters,
         beforeSend: function() {
           app.showLoader();
         }
      })
      .done(function(response) {
        $(target).append(response);
      })
      .fail(function() {
        swal({
          title: '<i class="fa fa-exclamation-triangle text-danger fa-3x mb-3"></i><br>Attenzione!',
          html: 'Si &egrave; verificato un errore nella richiesta. Si prega di riprovare...',
          showCloseButton: false,
          showConfirmButton: true,
          showCancelButton: false,
          confirmButtonClass: 'btn btn-outline-danger mx-2',
          confirmButtonText: '<i class="fa fa-undo"></i> Riprova'
        });
      })
      .always(function() {
        app.hideLoader();
        app.setCurrencyFormat();
        app.setLabel();
        app.enableDelete();
        app.tinymceEnabler();
      });
    });
    return false;
  }

  this.enableDelete = function() {
    $('[data-op="delete"]').unbind('click');
    $('[data-op="delete"]').on('click', function (e) {
      var endpoint = $(this).data('route');
      var element = $(this).data('element');
      e.preventDefault();
      swal({
        title: '<span class="text-warning"><i class="fa fa-exclamation-triangle fa-3x mb-3"></i><br>Attenzione!</span>',
        html: 'Stai per eliminare definitivamente questo elemento.<br>Vuoi continuare?',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonClass: 'btn btn-outline-success mx-2',
        cancelButtonClass: 'btn btn-outline-danger mx-2',
        confirmButtonText: '<i class="fa fa-thumbs-up"></i> Si!',
        cancelButtonText: '<i class="fa fa-thumbs-down"></i> No!'
      }).then( function() {
        $.ajax({
           url: endpoint,
           headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
           },
           type: 'POST',
           dataType: 'json',
           data: {_method: 'delete'},
           beforeSend: function() {
             app.showLoader();
           }
        })
        .done(function(response) {
          var title = '<i class="fa fa-exclamation-triangle text-danger fa-3x mb-3"></i><br>Attenzione!';
          var html = 'Si &egrave; verificato un errore nella richiesta. Si prega di riprovare...';
          var btn = 'btn btn-outline-danger mx-2';
          var btntxt = '<i class="fa fa-undo"></i> Riprova';
          if(response.hasOwnProperty("success")) {
            title = '<i class="fa fa-check-circle text-success fa-3x mb-3"></i><br>Complimenti!';
            html = response.message;
            btn = 'btn btn-outline-success mx-2';
            btntxt = '<i class="fa fa-thumbs-up"></i> Ok';
          }
          if(! response.hasOwnProperty("script")) {
            swal({
              title: title,
              html: html,
              showCloseButton: false,
              showConfirmButton: true,
              showCancelButton: false,
              confirmButtonClass: btn,
              confirmButtonText: btntxt
            }).then( function() {
              if(typeof table !== typeof undefined) {
                table.ajax.reload();
              } else if (typeof element !== typeof undefined) {
                $(element).remove();
              } else {
                window.location.reload();
              }
            });
          } else {
            eval(response.script);
          }
        })
        .fail(function() {
          var title = '<i class="fa fa-exclamation-triangle text-danger fa-3x mb-3"></i><br>Attenzione!';
          var html = 'Si &egrave; verificato un errore nella richiesta. Si prega di riprovare...';
          var btn = 'btn btn-outline-danger mx-2';
          var btntxt = '<i class="fa fa-undo"></i> Riprova';
          swal({
            title: title,
            html: html,
            showCloseButton: false,
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonClass: btn,
            confirmButtonText: btntxt
          }).then( function() {
            if(typeof table !== typeof undefined) {
              table.ajax.reload();
            } else {
              window.location.reload();
            }
          });
        })
        .always(function() {
          app.hideLoader();
        });
      });
    });
    return false;
  }

  this.validationSetup = function() {
    $.extend( $.validator.messages, {
      minWords: $.validator.format( "Inserisci almeno {0} parole" )
    });
    $.validator.setDefaults({
      ignore: ".ignore",
      ignoreTitle: true,
      success: "valid",
      highlight: function(element) {
        $(element).addClass('is-invalid');
        $(element).closest('.form-group').find('label').addClass('text-danger');
      },
      unhighlight: function(element) {
        $(element).removeClass('is-invalid');
        $(element).closest('.form-group').find('label').removeClass('text-danger');
        $(element).closest('.form-group').find('.invalid-feedback').removeClass('d-inline');
      },
      errorPlacement: function(error, element) {
        var errorContainer = $(element).closest('.form-group').find('.invalid-feedback');
        if(errorContainer.length) {
          errorContainer.html(error.text());
          if($(element).parent().hasClass('input-group')) {
            errorContainer.addClass('d-inline');
          }
        }
      },
      submitHandler: function(form) {
        form.submit();
      },
      invalidHandler: function(event, validator) {
        var errors = validator.numberOfInvalids();
        if(errors) {
          var alert = '<span class="h4"><i class="fa fa-meh-o mr-2"></i>Oooooops! Controlla tutti i campi obbligatori:</span><ul>';
          for (var i = validator.errorList.length - 1; i >= 0; i--) {
            alert += '<li>' + validator.errorList[i].element.title + ': ' + validator.errorList[i].message + '</li>';
          }
          alert += '</ul>';
          if($('#form-invalid-messages').length < 1) {
            alert = '<div id="form-invalid-messages" class="alert alert-danger mb-3">' + alert + '</div>';
            $(alert).insertBefore($(validator.currentForm));
          } else {
            $('#form-invalid-messages').html(alert).removeClass('d-none');
          }
        }
      }
    });
  }

  this.ajaxSetup = function() {
    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      }
    });
  }

  this.drawertoogle = function () {
    $('[data-toogle="drawer"]').on('click', function (e) {
      e.preventDefault();
      if($('body').hasClass('drawer-out')) {
        $('body').toggleClass('drawer-out');
      } else {
        if($('body').hasClass('drawer-in') || $('aside').css('left') == '-240px') {
          $('body').toggleClass('drawer-in');
        } else {
          $('body').toggleClass('drawer-out');
        }
      }
    });
  }

  this.toggleState = function() {
    $('[data-toggle="status"]').unbind('click');
    $('[data-toggle="status"]').on('click', function (e) {
      e.preventDefault();
      $.ajax({
        url: $(this).data('route'),
        type: 'POST',
        dataType: 'json',
        data: {id: $(this).data('id')},
        beforeSend: function(request) {
          request.setRequestHeader('X-CSRF-TOKEN', $('meta[name="csrf-token"]').attr('content'));
        }
      })
      .done(function(response) {
        var title = '<i class="fa fa-exclamation-triangle text-danger fa-3x mb-3"></i><br>Attenzione!';
        var html = 'Si &egrave; verificato un errore nella richiesta. Si prega di riprovare...';
        var btn = 'btn btn-outline-danger mx-2';
        var btntxt = '<i class="fa fa-undo"></i> Riprova';
        if(response.hasOwnProperty("success")) {
          title = '<i class="fa fa-check-circle text-success fa-3x mb-3"></i><br>Complimenti!';
          html = response.message;
          btn = 'btn btn-outline-success mx-2';
          btntxt = '<i class="fa fa-thumbs-up"></i> Ok';
        }
        swal({
          title: title,
          html: html,
          showCloseButton: false,
          showConfirmButton: true,
          showCancelButton: false,
          confirmButtonClass: btn,
          confirmButtonText: btntxt
        }).then( function() {
          if(typeof table !== typeof undefined) {
            table.ajax.reload();
          } else {
            window.location.reload();
          }
        });
      })
      .fail(function() {
        swal({
          title: '<i class="fa fa-exclamation-triangle text-danger fa-3x mb-3"></i><br>Attenzione!',
          html: 'Si &egrave; verificato un errore nella richiesta. Si prega di riprovare...',
          showCloseButton: false,
          showConfirmButton: true,
          showCancelButton: false,
          confirmButtonClass: 'btn btn-outline-danger mx-2',
          confirmButtonText: '<i class="fa fa-undo"></i> Riprova'
        }).then( function() {
          if(typeof table !== typeof undefined) {
            table.ajax.reload();
          } else {
            window.location.reload();
          }
        });
      });

    });
  }

  this.tooltipsEnable = function() {
    if($('[data-toggle="tooltip"]').length > 0) {
      $('[data-toggle="tooltip"]').tooltip();
    }
  }

  this.setupJqueryFileUpload = function() {
    $.widget('blueimp.fileupload', $.blueimp.fileupload, {
      options: {
        add: function (e, data) {
          var options = {
            title: '<i class="fa fa-exclamation-triangle text-warning fa-3x mb-3"></i><br>Attenzione!',
            html: "Si &egrave; verificato un errore durante il tentativo di salvataggio. Si prega di riprovare..",
            confirmButtonText: "Riprova",
            confirmButtonClass: 'btn btn-outline-warning',
          };
          if((typeof checkData === typeof Function && checkData()) || typeof checkData !== typeof Function) {
            var canUpload = true;
            var acceptFileTypes = /^(^image\/(gif|jpe?g|png))|(video\/mp4)|(text\/(plain|csv|xml|html))|(application\/(pkcs7-mime|pdf|x-7z-compressed|json|vnd.oasis.opendocument.text|vnd.oasis.opendocument.spreadsheet|msword|x-msdownload|vnd.ms-excel|vnd.openxmlformats-officedocument.spreadsheetml.sheet|vnd.openxmlformats-officedocument.presentationml.presentation|vnd.ms-powerpoint|zip|x-rar-compressed))|(multipart\/x-zip)$/i;
            $.each(data.files, function (index, file) {
              if(! acceptFileTypes.test(file.type)) {
                canUpload = false;
                options.html = "Il file selezionato non &egrave; valido per questa tipologia di Box!";
              }
            });
            if(canUpload) {
              data.submit();
              return;
            }
          }
          swal(options);
        }
      }
    });
  }

  this.tinymceEnabler = function () {
    if(typeof tinymce !== typeof undefined) {
      if($('.tinymce').length > 0) {
        tinymce.init({
          content_css: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css',
          noneditable_noneditable_class: 'fa',
          extended_valid_elements: 'span[*]',
          selector: '.tinymce',
          branding: false,
          language: 'it',
          language_url: '/adm/plugin/tinymce/plugins/langs/it.js',
          height: 300,
          menubar: true,
          theme: 'modern',
          fontsize_formats: "8px 10px 12px 14px 16px 18px 24px 36px 48px 64px 72px 96px 144px",
          plugins: [
            'fontawesome noneditable',
            'advlist autolink lists link image charmap preview hr anchor pagebreak',
            'searchreplace wordcount visualblocks visualchars code fullscreen',
            'insertdatetime media nonbreaking save table contextmenu directionality',
            'emoticons template paste textcolor colorpicker textpattern imagetools codesample toc'
          ],
          font_formats: "Rotterdam=Rotterdam; Ndiscover=Ndiscover; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
          toolbar1: 'undo redo | insert | bold italic | fontselect | fontsizeselect | alignleft aligncenter alignright alignjustify | forecolor backcolor | bullist numlist outdent indent | link image | preview media | fontawesome | code',
          toolbar2: false,
          image_advtab: true,
          extended_valid_elements: ['span[*]','script[language|type|src]'],
          relative_urls : false,
          allow_script_urls : true,
          remove_script_host : false,
          convert_urls : false,
          content_style: `
          @font-face{
              font-family: "Rotterdam";
              src:url("/resources/fonts/Rotterdam.ttf");
              src:url("/resources/fonts/Rotterdam.otf");
          }
          
          @font-face{
              font-family: "Ndiscover";
              src:url("/resources/fonts/NdiscoverBold.otf");
              font-style: bold;
          }
        `,
          setup: function (editor) {
            editor.on('change', function () {
              editor.save();
            });
          }
        });
      }
      if($('.tinymce-simple').length > 0) {
        tinymce.init({
          content_css: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css',
          noneditable_noneditable_class: 'fa',
          extended_valid_elements: 'span[*]',
          selector: '.tinymce-simple',
          branding: false,
          language: 'it',
          language_url: '/adm/plugin/tinymce/plugins/langs/it.js',
          height: 250,
          menubar: false,
          fontsize_formats: "8px 10px 12px 14px 16px 18px 24px 36px 48px 64px 72px 96px 144px",
          noneditable_noneditable_class: 'fa',
          plugins: [
              'fontawesome noneditable',
              'advlist autolink lists link charmap',
              'searchreplace',
              'contextmenu paste'
          ],
          font_formats: "Rotterdam=Rotterdam; Ndiscover=Ndiscover; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
          toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | fontselect ',
          extended_valid_elements: 'span[*]',
          relative_urls : false,
          remove_script_host : false,
          convert_urls : true,
          content_style: `
          @font-face{
              font-family: "Rotterdam";
              src:url("/resources/fonts/Rotterdam.ttf");
              src:url("/resources/fonts/Rotterdam.otf");
          }
          
          @font-face{
              font-family: "Ndiscover";
              src:url("/resources/fonts/NdiscoverBold.otf");
              font-style: bold;
          }
        `,
          setup: function (editor) {
            editor.on('change', function () {
              editor.save();
            });
          }
        });
      }
    }
  }

  this.setCurrencyFormat = function() {
    var currencyInputs = $('.currency');
    if(currencyInputs.length) {
      for (var i = currencyInputs.length - 1; i >= 0; i--) {
        $(currencyInputs[i]).currencyFormat().trigger('change');
      }
    }
  }

  this.setLabel = function() {
    var inputs = $("* :input");
    if(inputs.length) {
      for (var i = inputs.length - 1; i >= 0; i--) {
        var title = $(inputs[i]).attr('title');
        if(typeof title !== typeof undefined && title !== false) {
          $(inputs[i]).attr("placeholder", title);
        }
      }
    }
  }

  this.resetdrawer = function() {
    $(window).resize(function(event) {
      event.preventDefault();
      event.stopPropagation();
      $('body').removeClass('drawer-out drawer-in');
    });
  }
}

module.exports = application;