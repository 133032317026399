
window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */


// try {

//     window.$ = window.jQuery = require('jquery');

//     require('bootstrap-sass');

// } catch (e) {}
window.$ = window.jQuery = global.$ = global.jQuery = require('jquery');

(function($) {
  $.fn.currencyFormat = function() {
    this.each( function( i ) {
      $(this).change( function( e ){
        this.value = this.value.replace(",", ".");
        if( isNaN( parseFloat( this.value ) ) ) return;
        this.value = parseFloat(this.value).toFixed(2);
      });
    });
    return this;
  }
})( jQuery );

require('bootstrap');

require('datatables.net-bs4');

require('jquery-ui/ui/core.js');

require('jquery-ui/ui/widgets/sortable.js');

const getSlug = window.getSlug = require('speakingurl/lib/speakingurl.js');
require('jquery-slugify/dist/slugify.js');

const swal = window.swal = require('sweetalert2');
swal.mixin({
  confirmButtonClass: 'btn btn-outline-info mx-2',
  cancelButtonClass: 'btn btn-outline-danger mx-2',
  buttonsStyling: false
});

const application = require('./components/function');
const app = window.app = new application();
$(function() {
  app.ready();
});

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: 'your-pusher-key'
// });
